<template>
	<overlay :visible="visible" @close="close">
		<panel class="tablet:w-6/12 tabletWide:w-5/12 desktop:w-4/12 desktopWide:w-3/12" data-test="formItineraryPromoCodeModal" @close="close">
			<template #header>
				<div class="p-16 tablet:px-24">
					<h2 class="font-bold text-16">Promo code</h2>
				</div>
			</template>

			<template #content>
				<div class="p-16 tablet:px-24">
					<template v-if="allErrors.length > 0">
						<errorMessage :content="allErrors" class="mb-32" title="Sorry" :promoCode="promoCode"/>
					</template>

					<label :class="[errors.has('promoCode') ? 'border-2 border-goError bg-goErrorBright' : 'border-transparent focus-within:border-goGrayBright bg-goGrayBrighter']" class="block placeholder:text-goGray border-2 rounded-3 py-6 px-16" data-test="promoCode">
						<input v-model.trim="promoCode" v-validate="'required'" spellcheck="false" placeholder="Enter code" class="border-0 bg-transparent p-0 outline-0 w-full" type="text" name="promoCode"/>
					</label>

					<label v-if="addOnRequired" :class="[errors.has('promoCodeAddon') ? 'border-2 border-goError bg-goErrorBright' : 'border-transparent focus-within:border-goGrayBright bg-goGrayBrighter']" class="block placeholder:text-goGray border-2 rounded-3 py-6 px-16 mt-16">
						<input v-model.trim="promoCodeAddon" v-validate="'required|max:16|integer'" name="promoCodeAddon" spellcheck="false" placeholder="AA membership number" class="border-0 bg-transparent p-0 outline-0 w-full" type="text" />
					</label>
				</div>

				<button class="bg-goPink hover:bg-goPinkDark active:bg-goPinkDarker cursor-pointer border-0 text-white font-bold text-18 outline-0 p-14 whitespace-nowrap w-full" data-test="applyCode" @click="validate">Apply code</button>
			</template>
		</panel>
	</overlay>
</template>

<script>
	import modalBase from "~/components/modals/modalBase.vue";
	export default {
		name: "FormItineraryPromoCode",
		components: {
			panel: () => import("~/components/modals/panel.vue"),
			errorMessage: () => import("~/components/errorMessage.vue"),
		},
		extends: modalBase,
		props: {
			code: {
				type: String,
				default: null,
			},
			currentItinerary: {
				type: Object,
				default: null,
			}
		},
		data() {
			return {
				promoCode: this.code || this.$store.state.itinerary.promoCode,
				promoCodeAddon: this.$store.state.itinerary.clientReferenceNumber,
				addons: ["aamembership", "aamember", "aamemberdeal"],
				allErrors: [],
			};
		},
		computed: {
			itinerary() {
				if (!!this.currentItinerary) {
					return this.currentItinerary;
				}

				return this.$store.state.itinerary;
			},
			addOnRequired() {
				return !!this.promoCode && this.addons.includes(this.promoCode.toLowerCase());
			},
		},
		methods: {
			showErrors(error) {
				this.allErrors = [];

				// prettier-ignore
				const message = error && error instanceof Error
					? error.message
					: error;

				if (message) {
					this.allErrors.push(message);
				}
			},
			validate() {
				this.allErrors = [];
				this.$validator.validateAll().then((result) => {
					if (result) {
						if (this.addOnRequired && this.promoCodeAddon) {
							this.validateAAMembership(this.promoCodeAddon);
						} else {
							this.fetchVehicles();
						}
					}
				});
			},
			validateAAMembership(aamembershipNumber) {
				this.$axios.$get("/booking/validateAAMembership?aamembershipNumber=" + aamembershipNumber).then((valid) => {
					if (!valid) {
						this.allErrors.push("Invalid AA membership number.");
					} else {
						this.fetchVehicles();
					}
				});
			},
			fetchVehicles() {
				const promoObj = {
					promoCode: this.promoCode.toLowerCase(),
					clientReferenceNumber: this.addOnRequired ? this.promoCodeAddon : null,
				};

				if (this.$store.getters["itinerary/isEmpty"]) {
					this.$store.commit("itinerary/updateItinerary", promoObj);
					this.ok(); // close the promo modal

					if (window.location.href.includes("deals/promotion")) {
						this.$router.push({
							path: "/vehicles/",
						});
					}
				} else {
					const itineraryWithPromoCode = {
						...this.itinerary,
						...promoObj,
					};

					const redirect = () => {
						this.$router.push({
							path: "/vehicles/",
						});
					};

					const scrollToResults = () => {
						if (this.$store.state.browser.breakpoint.isMobileOnly) {
							this.$scrollTo("searchResults");
						}
					};

					this.$store
						.dispatch("vehicle/fetch", itineraryWithPromoCode)
						.then(() => {
							this.ok(); // close the promo modal
						})
						.then(redirect)
						.then(scrollToResults)
						.catch(this.showErrors);
				}
			},
		},
	};
</script>
