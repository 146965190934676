<template>
	<div v-if="isShow" :class="isMobile ? 'h-[3.25rem] text-14 leading-5' : 'h-9 hidden tablet:flex'" class="bar flex justify-center items-center order-first bg-goPink text-white font-bold text-center">
		<nuxt-link to="/deals/promotion/?code=_25yearsofgo" class="hover:opacity-90 active:opacity-80 py-6 px-12 transition-opacity flex items-center gap-2">
			<span :class="!isMobile && 'flex items-center'">
				<svg-icon :class="isMobile ? 'iconSize-18 mr-4' : 'iconSize-20 mr-8'" class="tag-icon" name="tag-solid" />
				Celebrating 25 years of GO:
				<br v-if="isMobile" />
				WIN Your Share of $25,000! *T&Cs apply
			</span>
		</nuxt-link>
		<button class="absolute right-6 p-6 opacity-50" aria-label="Close promo bar" @click="onHide">
			<svg-icon name="x" title="Close" class="iconSize-24" />
		</button>
	</div>
</template>

<script>
export default {
	props: {
		isMobile: {
			type: Boolean,
			default: false,
		},
		isShow: {
			type: Boolean,
			required: true,
		},
		onHide: {
			type: Function,
      		required: true
		},
	},
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 360px) {
	.bar {
		font-size: 0.75rem;
	}
	.tag-icon {
		width: 1rem;
		height: 1rem;
	}
}
</style>
