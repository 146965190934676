import { favicons } from "~/assets/scripts/utilities/favicons";

const renderGoogleTagManager = (googleTagManagerId, gtmTestEnvironmentParameters) => {
	if (!googleTagManagerId) return ''
	const gtmTestEnvironment = !gtmTestEnvironmentParameters ? '' : `+ '${gtmTestEnvironmentParameters}'`;
	return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl${gtmTestEnvironment};f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleTagManagerId}');`
}

export const meta = (vm) => {
	return [
		...favicons.meta,

		{ vmid: 'robots',	name: "robots",							content: vm.$config.meta_robots || 'noindex, nofollow',				},

		{ once: true,	name: "theme-color",					content: "#E21266",													},
		{ once: true,	name: "facebook-domain-verification",	content: "fusz1ucvw3spez9ilz4d8e64rytosq",							},
		{ once: true,	name: "google-site-verification",		content: "uSgeaUPHgQyP9VxFfDt5Xmkmv0F-cLCSzKPOJ2a2STI",				},
		{ once: true,	name: "apple-itunes-app",				content: "app-id=1052970337",										},

		{ vmid: "oglocale", 	property: "og:locale",			content: "en_NZ",													},
		{ vmid: "ogtype", 		property: "og:type",			content: "website",													},
		{ vmid: "ogtitle", 		property: "og:title",			content: "GoRentals",												},
		{ vmid: "ogurl", 		property: "og:url",				content: "https://www.gorentals.co.nz/",							},
		{ vmid: "ogimage", 		property: "og:image",			content: "https://www.gorentals.co.nz/images/logo-go-rentals.svg",	},
	]
}

const link = (vm) => {
	return [
		...favicons.link,

		{ once: true, rel: "sitemap",			type: "application/xml",				href: "/sitemap.xml", },

		{ once: true, rel: "preconnect", href: "https://bat.bing.com/",					crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://c.bing.com/",					crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://www.facebook.com/",				crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://www.google-analytics.com/",		crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://www.googletagmanager.com/",		crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://www.google.com/",				crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://bid.g.doubleclick.net/",		crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://googleads.g.doubleclick.net/",	crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://www.googleadservices.com/",		crossorigin: "", },
		{ once: true, rel: "preconnect", href: "https://ajax.cloudflare.com/",			crossorigin: "", },
	]
}

const structuredData = {
	"@context": "http://schema.org",
	"@type": "Organization",
	"name": "GO Rentals",
	"url": "https://www.gorentals.co.nz/",
	"logo": "https://www.gorentals.co.nz/logo/go-rentals.png",
	"foundingDate": "1997",
	"contactPoint": [
		{
			"@type": "ContactPoint",
			"telephone": "+640800467368",
			"contactType": "Customer service",
			"areaServed": "NZ",
		},
		{
			"@type": "ContactPoint",
			"telephone": "+611800071857",
			"contactType": "Customer service",
			"areaServed": "AU",
		},
		{
			"@type": "ContactPoint",
			"telephone": "+118005841330",
			"contactType": "Customer service",
			"areaServed": "US",
		},
		{
			"@type": "ContactPoint",
			"telephone": "+4408000287840",
			"contactType": "Customer service",
			"areaServed": "UK",
		},
		{
			"@type": "ContactPoint",
			"telephone": "+006499741598",
			"contactType": "Customer service",
			"areaServed": "Worldwide",
		},
	],
	"sameAs": ["https://www.facebook.com/gorentalsnz", "https://www.instagram.com/gorentalsnz/", "https://www.youtube.com/user/GORentalsNZCarRental", "https://nz.linkedin.com/company/go-rentals-new-zealand"],
}

const script = (vm) => {
	const { googleTagManagerId, gtmTestEnvironmentParameters } = vm.$config.thirdParty

	return [
		{ vmid: "Organization",	type: "application/ld+json", json: structuredData, },
		{ once: true, type: "text/javascript", innerHTML: renderGoogleTagManager(googleTagManagerId, gtmTestEnvironmentParameters), },
		{ once: true, type: "text/javascript", src: "https://static.elfsight.com/platform/platform.js", async: true }
	]
}

export const headInfo = (vm) => {
	if (process.server) {
		return {
			htmlAttrs: { lang: "en-NZ", },
			titleTemplate: (titleChunk) => { return titleChunk || "GO Rentals - NZ’s Favourite Rental Car Company" },
			__dangerouslyDisableSanitizers: ['script'],
			meta: meta(vm),
			link: link(vm),
			script: script(vm),
		};
	}
}
